Scrollbar
/* ::-webkit-scrollbar {
    width: 12px; Width of the scrollbar
    height: 12px; Height of the scrollbar
  } */
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
    border-radius: 6px; /* Roundness of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
  }
  .active {
    background-color: #FF0000; /* Blue color, change it to your preferred color */
    /* color: white; Text color when active */
  }
  
  body{
    background-color: #f0f0f0;
  }
  .slider-container {
    max-width: 100vw;
    width: 100%;
    overflow: hidden;
}
.sidebar-same {
  background-image: linear-gradient(-225deg, #2e3193 0%, #2e3193 29%, #FF1361 67%, #FFF800 100%);
}